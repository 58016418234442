<template>
  <el-date-picker
      v-model="innerValue"
      :format="format"
      :type="inputType"
      range-separator="-"
      :value-format="valueFormat"
      @change="changeValue"
      :clearable="clearable"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :picker-options="pickerOptions"
  >
  </el-date-picker>
</template>

<script>
import i18n from '../../../plugins/i18n'

const INPUT_TYPES = [
  'year',
  'month',
  'date',
  'dates',
  'datetime',
  'week',
  'datetimerange',
  'daterange',
  'monthrange',
]

export default {
  name: 'VDatePicker',
  props: {
    value: {
      type: [String, Array, Date],
      required: false
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd',
      required: false,
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd',
      required: false,
    },
    inputType: {
      type: String,
      required: false,
      default: 'date',
      validator: (v) => {
        const index = INPUT_TYPES.indexOf(v)
        if (index === -1) {
          console.error(`Expected input types: ${INPUT_TYPES}. Given: ${v}`)
          return false
        }
         return true
      }
    },
    rangeSeparator: {
      type: String,
      required: false,
      default: '-'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    startPlaceholder: {
      type: String,
      required: false,
      default: () => {
        return i18n.t('components.date_picker.start_placeholder')
      }
    },
    endPlaceholder: {
      type: String,
      required: false,
      default: () => {
        return  i18n.t('components.date_picker.end_placeholder')
      }
    },
    pickerOptions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      innerValue: this.value
    }
  },
  methods: {
    changeValue(val) {
      this.$emit('input', val)
    },
  },
}
</script>
