var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-card',[_c('h2',{staticClass:"heading page-heading"},[_vm._v(_vm._s(_vm.$t('page_finance_report.title')))]),_c('bank-statement-upload-modal'),_c('div',{staticClass:"table-params"},[_c('v-date-picker',{attrs:{"input-type":"daterange","start-placeholder":_vm.$t('page_finance_report.date_picker.start_placeholder'),"end-placeholder":_vm.$t('page_finance_report.date_picker.end_placeholder')},on:{"input":_vm.fetchReportItems},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('div',[_c('v-button',{attrs:{"type":"primary","icon":"web_asset"},on:{"click":_vm.fetchOrdersReport}},[_vm._v(" "+_vm._s(_vm.$t('page_finance_report.btn_orders_report'))+" ")]),_c('v-button',{attrs:{"type":"primary","icon":"web_asset"},on:{"click":_vm.$options.fetchSubscriptionReport}},[_vm._v(" "+_vm._s(_vm.$t('page_finance_report.btn_subscription_report'))+" ")])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"mt-1",staticStyle:{"width":"100%"},attrs:{"data":_vm.financeReportItemsToShow,"row-class-name":_vm.applyRowClass},on:{"sort-change":_vm.customSort}},[_c('el-table-column',{attrs:{"prop":"title","width":_vm.$options.colWidths.title,"sortable":"custom","label":_vm.$t('page_finance_report.table_headers.title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_vm._v(" "+_vm._s($index === 0 ? 'TOTAL' : row.title)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"type","width":100,"sortable":"custom","label":_vm.$t('page_finance_report.table_headers.unit_type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_vm._v(" "+_vm._s($index === 0 ? '-' : _vm.$t(("unitTypes." + (row.type))))+" ")]}}])}),_c('el-table-column',{attrs:{"width":_vm.$options.colWidths.supportedChildren,"sortable":"custom","class-name":"text-right","label":_vm.$t('page_finance_report.table_headers.adoption')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.children)+" "),_c('br'),_vm._v(" "+_vm._s(row.supportedChildren)+" ")]}}])}),_c('el-table-column',{attrs:{"width":_vm.$options.colWidths.collectedMoney,"sortable":"custom","class-name":"text-right","label":_vm.$t('page_finance_report.table_headers.purpose_support')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatMoneyContent(row.moneyPurpose))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.formatMoneyContent(row.collectedMoney))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"totalMoney","width":_vm.$options.colWidths.totalMoney,"sortable":"custom","class-name":"el-table__cell--highlighted text-right","label":_vm.$t('page_finance_report.table_headers.support_payment_total')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatMoneyContent(row.totalMoney))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"supportedChildrenMoney","width":_vm.$options.colWidths.supportedChildrenMoney,"sortable":"custom","class-name":"text-right","label":_vm.$t('page_finance_report.table_headers.subscription_payment_total')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatMoneyContent(row.supportedChildrenMoney))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }