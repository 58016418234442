<template>
  <div class="app-container"
  >
    <el-card>
      <h2 class="heading page-heading">{{ $t('page_finance_report.title') }}</h2>
      <bank-statement-upload-modal />
      <div class="table-params">
        <v-date-picker v-model="dateRange"
                       input-type="daterange"
                       @input="fetchReportItems"
                       :start-placeholder="$t('page_finance_report.date_picker.start_placeholder')"
                       :end-placeholder="$t('page_finance_report.date_picker.end_placeholder')"
        />
        <div>
          <v-button type="primary" icon="web_asset" @click="fetchOrdersReport">
            {{ $t('page_finance_report.btn_orders_report') }}
          </v-button>
          <v-button type="primary" icon="web_asset" @click="$options.fetchSubscriptionReport">
            {{ $t('page_finance_report.btn_subscription_report') }}
          </v-button>

        </div>
      </div>
      <el-table
          class="mt-1"
          v-loading="isLoading"
          :data="financeReportItemsToShow"
          style="width: 100%"
          @sort-change="customSort"
          :row-class-name="applyRowClass"
      >
        <el-table-column
            prop="title"
            :width="$options.colWidths.title"
            sortable="custom"
            :label="$t('page_finance_report.table_headers.title')"
        >
          <template #default="{row, $index}">
            {{ $index === 0 ? 'TOTAL' : row.title }}
          </template>
        </el-table-column>
        <el-table-column
            prop="type"
            :width="100"
            sortable="custom"
            :label="$t('page_finance_report.table_headers.unit_type')"
        >
          <template #default="{row, $index}">
            {{ $index === 0 ? '-' : $t(`unitTypes.${row.type}`) }}
          </template>
        </el-table-column>
        <el-table-column
            :width="$options.colWidths.supportedChildren"
            sortable="custom"
            class-name="text-right"
            :label="$t('page_finance_report.table_headers.adoption')"
        >
          <template #default="{row}">
            {{ row.children }}
            <br />
            {{ row.supportedChildren }}
          </template>
        </el-table-column>
        <el-table-column
            :width="$options.colWidths.collectedMoney"
            sortable="custom"
            class-name="text-right"
            :label="$t('page_finance_report.table_headers.purpose_support')"
        >
          <template #default="{row}">
            {{ formatMoneyContent(row.moneyPurpose) }}
            <br />
            {{ formatMoneyContent(row.collectedMoney) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="totalMoney"
            :width="$options.colWidths.totalMoney"
            sortable="custom"
            class-name="el-table__cell--highlighted text-right"
            :label="$t('page_finance_report.table_headers.support_payment_total')"
        >
          <template #default="{row}">
            {{ formatMoneyContent(row.totalMoney) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="supportedChildrenMoney"
            :width="$options.colWidths.supportedChildrenMoney"
            sortable="custom"
            class-name="text-right"
            :label="$t('page_finance_report.table_headers.subscription_payment_total')"
        >
          <template #default="{row}">
            {{ formatMoneyContent(row.supportedChildrenMoney) }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    
  </div>
</template>

<script>
/*
*
* Summary made as the second table
* possible better solution:
* see: ProfileAnnualReport.vue
*
* */
import {tableSort} from '../../../shared/utils/tableSort'
import {
  fetchFinanceReport,
  fetchSubscriptionReport,
  fetchOrdersReport
} from '../services/report-service'
import {tableSummaryCounter} from '../../../shared/utils/tableSummaryCounter'
import {moneyFormat} from '../../../shared/utils/moneyFormat'
import VDatePicker from '../../../components/ui/v-date-picker/VDatePicker'
import VButton from '../../../components/ui/v-button/VButton'
import BankStatementUploadModal from '../../../components/bank-statement-upload-modal/BankStatementUploadModal'


const getYesterdayDate = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date.toISOString().slice(0, 10)
}


const DEFAULT_DATE_RANGE = [
  '2021-12-01',
  getYesterdayDate()
]

export default {
  name: 'FinanceReport',
  components: {VButton, VDatePicker, BankStatementUploadModal},
  fetchSubscriptionReport,
  colWidths: {
    title: 'auto',
    supportedChildren: 200,
    collectedMoney: 150,
    totalMoney: 150,
    supportedChildrenMoney: 180
  },
  data() {
    return {
      isLoading: false,
      financeReportItems: [],
      dateRange: DEFAULT_DATE_RANGE,
    }
  },
  created() {
    this.fetchReportItems()
  },
  computed: {
    tableSummary() {
      return tableSummaryCounter(this.financeReportItems)
    },
    financeReportItemsToShow() {
      const items = []
      if (this.financeReportItems.length > 0) {
        items.unshift(this.tableSummary)
      }
      return [...items, ...this.financeReportItems]
    }
  },
  methods: {
    formatMoneyContent(val) {
      return moneyFormat(val, {
        maximumFractionDigits: 2,
      })
    },
    applyRowClass({rowIndex}) {
      if (rowIndex === 0 ) {
        return 'el-table__cell--highlighted'
      }
    },
    customSort(sortInfo) {
      this.financeReportItems = tableSort(this.financeReportItems, sortInfo)
    },
    fetchOrdersReport() {
      fetchOrdersReport(this.dateRange)
    },
    fetchReportItems(dateRange) {
      this.isLoading = true
      fetchFinanceReport(dateRange)
          .then(res => {
            this.financeReportItems = res
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'fetch_error'
            })
          })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.summary-row, .el-table__cell--highlighted {
  background-color: #e7e7e7!important;
  font-weight: 600;
}
.table-params {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include tablet {
    .el-date-editor {
      margin-bottom: 1em;
    }
    .el-button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 1em;
    }
  }
  .el-date-editor {
    height: 40px;
  }
}
</style>
