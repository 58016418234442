const tableSummaryCounter = (array) => {
  if (array.length === 0) {
    return
  }
  
  const summary = []
  const columns = Object.keys(array[0])
  
  columns.forEach((col, index) => {
    
    if (index === 0) {
      summary[index] = 'TOTAL'
      return
    }
    
    const values = array.map((item) => Number(item[col]))
    
    if ( !values.every( (value) => isNaN(value) ) ) {
      let INIT_VALUE = 0
      summary[index] = values.reduce(
        (prev, current) => {
          return prev + current
        },
        INIT_VALUE
      )
    }
    else {
      summary[index] = 'N/A'
    }
  })
  
  const result = {}
  
  columns.forEach((name, index) => {
    result[name] = summary[index]
  })
  
  return result
}

export {
  tableSummaryCounter
}
