import {api} from '../../../shared/services/backend-api'

const RESOURCE = 'bank-statement-upload'

const uploadBankStatements = (statements) => {
  const payload = new FormData()
  statements.forEach(({raw}) => {
    payload.append('statements[]', raw)
  })
  
  return api.post(`${RESOURCE}`, payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
}

export {uploadBankStatements}

